<template>
  <Page id="softwaredev" anchor="softwaredev">
    <ServicesCard
      anchor="softwaredev-anchor"
      title="Bespoke Software Development"
      img="pitch_meeting.webp"
      alt="Man presenting in pitch meeting"
    >
      <p>
        We provide expert development and engineering skills to create real life
        solutions from your ideas and innovative needs.We pride ourselves in
        engineering to your requirements and enjoying the whole journey.
      </p>
    </ServicesCard>
    <ServicesSplit
      id="softwaredev-anchor"
      img="pitch_meeting.webp"
      problem="Often cloud native apps and off the shelf products do not fully meet the customer&#39;s requirements and will require either partial or full customisation. We assist you with your development of a business case for which route to take. We have seen well known products simply fail to deliver the performance required, the robustness and security required to satisfy our customers and their customers."
      solution="To ensure we are meeting the needs of our customers we take the gathering of requirements and business need so closely that we understand the business fully. Our agile approach adopts the best of thought and idea gathering methods as well as adaptive and efficient engineering to maximise the functionality required weeding out functional debt and maximising benefit in all of our solutions."
    />
  </Page>
</template>

<script>
import ServicesSplit from "@/components/pages/services/ServicesSplit.vue";
import ServicesCard from "@/components/pages/services/ServicesCard.vue";
import Page from "@/components/core/Page.vue";

export default {
  components: {
    ServicesSplit,
    ServicesCard,
    Page,
  },
};
</script>

<style scoped>
</style>